import React from 'react'
import Layout from '../components/layout'
import Meta from '../components/meta'
import Hero from '../components/dolphin/hero'
import DolphinLines from '../components/dolphin/dolphin-lines'
import WhichModel from '../components/which-model'
import Reviews from '../components/dolphin/reviews'
import DolphinDifference from '../components/dolphin/dolphin-difference'
import FindDealer from '../components/find-dealer'
import TopModels from '../components/dolphin/top-models'
import EliteDealers from '../components/dolphin/elite-dealers'
import { graphql } from 'gatsby'
import localeCheck from '../components/utils/locale-check'

import filterLocale from '../components/utils/filter-locale'

const Dolphin = ({
	data: {
		dolphin,
		xxsImage,
		xsImage,
		smImage,
		mdImage,
		lgImage,
		whichModel,
		reviews,
		videobanner,
		dolphinfeature,
		findDealer,
		dolphinLines,
		productImage,
		elitedealers,
		elitelg,
		elitemd,
		elitesm,
		elitemxs,
		elitexs
	},
}) => {
	const locale = 'en-ZA'
	const defaultLocale = 'en-AU'
	const [{ node }] = filterLocale(dolphin.edges, locale)
	const [{ node: whichmodel }] = filterLocale(whichModel.edges, locale)
	const [{ node: review }] = filterLocale(reviews.edges, locale)
	const [{ node: vidbanner }] = filterLocale(videobanner.edges, locale)
	const [{ node: dolphinfeatures }] = filterLocale(dolphinfeature.edges, locale)
	const [{ node: finddealer }] = filterLocale(findDealer.edges, locale)
	const [{ node: dolphinlines }] = filterLocale(dolphinLines.edges, locale)
	const [{ node: xxs }] = filterLocale(xxsImage.edges, locale)
	const [{ node: xs }] = filterLocale(xsImage.edges, locale)
	const [{ node: sm }] = filterLocale(smImage.edges, locale)
	const [{ node: md }] = filterLocale(mdImage.edges, locale)
	const [{ node: lg }] = filterLocale(lgImage.edges, locale)
	const [{ node: elite }] = filterLocale(elitedealers.edges, locale)
	const [{ node: eliteLg }] = filterLocale(elitelg.edges, locale)
	const [{ node: eliteMd }] = filterLocale(elitemd.edges, locale)
	const [{ node: eliteSm }] = filterLocale(elitesm.edges, locale)
	const [{ node: eliteMxs }] = filterLocale(elitemxs.edges, locale)
	const [{ node: eliteXs }] = filterLocale(elitexs.edges, locale)

	const filterAllByLocale = (items, locale) => {
		return items.filter((item, i) => {
			return item.node.node_locale === locale
		})
	}
	const topmodelimages= filterAllByLocale(productImage.edges, locale)
	const {
		seoTitle,
		seoKeywords,
		seoDescription,
		media: heroLineUrl,
		banner: {
			image,
			mainActionUrl,
			mainActionText,
			secondActionText,
			secondActionUrl,
			thirdActionText,
			thirdActionUrl,
			description,
		},
	} = dolphin.edges[0].node
	
	const sources = [
		{
			...lg.backgroundImage.fluid,
			srcSet: `${lg.backgroundImage.fluid.src} 1920w`,
			srcSetWebp: `${lg.backgroundImage.fluid.srcWebp} 1920w`,
			media: `(min-width: 1350px)`,
		},
		{
			...md.backgroundImage.fluid,
			srcSet: `${md.backgroundImage.fluid.src} 1024w`,
			srcSetWebp: `${md.backgroundImage.fluid.srcWebp} 1024w`,
			media: `(min-width: 1024px) and (max-width: 1349px)`,
		},
		{
			...sm.backgroundImage.fluid,
			srcSet: `${sm.backgroundImage.fluid.src} 768w`,
			srcSetWebp: `${sm.backgroundImage.fluid.srcWebp} 768w`,
			media: `(min-width: 768px) and (max-width: 1023px)`,
		},
		{
			...xs.backgroundImage.fluid,
			srcSet: `${xs.backgroundImage.fluid.src} 525w`,
			srcSetWebp: `${xs.backgroundImage.fluid.srcWebp} 525w`,
			media: `(min-width: 525px) and (max-width: 767px)`,
		},
		{
			...xxs.backgroundImage.fluid,
			srcSet: `${xxs.backgroundImage.fluid.src} 410w`,
			srcSetWebp: `${xxs.backgroundImage.fluid.srcWebp} 410w`,
			media: `(max-width: 524px)`,
		},
	]

	const eliteSources = [
		{
			...elite.backgroundImage.fluid,
			srcSet: `${elite.backgroundImage.fluid.src} 1920w`,
			srcSetWebp: `${elite.backgroundImage.fluid.srcWebp} 1920w`,
			media: `(min-width: 1700px)`,
		},
		{
			...eliteLg.backgroundImage.fluid,
			srcSet: `${eliteLg.backgroundImage.fluid.src} 1400w`,
			srcSetWebp: `${eliteLg.backgroundImage.fluid.srcWebp} 1400w`,
			media: `(min-width: 1400px) and (max-width: 1699px)`,
		},
		{
			...eliteMd.backgroundImage.fluid,
			srcSet: `${eliteMd.backgroundImage.fluid.src} 1280w`,
			srcSetWebp: `${eliteMd.backgroundImage.fluid.srcWebp} 1280w`,
			media: `(min-width: 1280px) and (max-width: 1399px)`,
		},
		{
			...eliteSm.backgroundImage.fluid,
			srcSet: `${eliteSm.backgroundImage.fluid.src} 1024w`,
			srcSetWebp: `${eliteSm.backgroundImage.fluid.srcWebp} 1024w`,
			media: `(min-width: 1024px) and (max-width: 1279px)`,
		},
		{
			...eliteMxs.backgroundImage.fluid,
			srcSet: `${eliteMxs.backgroundImage.fluid.src} 768w`,
			srcSetWebp: `${eliteMxs.backgroundImage.fluid.srcWebp} 768w`,
			media: `(min-width: 768px) and (max-width: 1023px)`,
		},
		{
			...eliteXs.backgroundImage.fluid,
			srcSet: `${eliteXs.backgroundImage.fluid.src} 524w`,
			srcSetWebp: `${eliteXs.backgroundImage.fluid.srcWebp} 524w`,
			media: `(min-width: 325px) and (max-width: 524px)`,
		},
	]

	const checkedLocale = localeCheck(locale, defaultLocale)
	const title = `${seoTitle} | Maytronics South Africa`
	const desc = `${seoDescription} | Maytronics South Africa`
	return (
		<Layout>
			<Meta
				title={title}
				keywords={seoKeywords}
				description={desc}
				lang={locale}
			/>

			<Hero
				{...{
					mainActionText,
					mainActionUrl,
					secondActionText,
					secondActionUrl,
					thirdActionText,
					thirdActionUrl,
					description,
					heroLineUrl,
					sources,
					image,
					checkedLocale,
					locale,
				}}
			/>
			<DolphinLines locale={locale} dolphinlines={dolphinlines} checkedLocale={checkedLocale}/>
			<WhichModel data={whichmodel} checkedLocale={checkedLocale}/>
			<Reviews data={review} />
			<DolphinDifference data={vidbanner} dolphinfeatures={dolphinfeatures} />
			<TopModels modelimages={topmodelimages} locale={locale} checkedLocale={checkedLocale}/>
			<EliteDealers elite={elite} sources={eliteSources} checkedLocale={checkedLocale}/>
			{/* <FindDealer finddealer={finddealer} /> */}
		</Layout>
	)
}

export const dolphinQuery = graphql`
query dolphinQueryZa {
  dolphin: allContentfulPage(filter: {contentful_id: {eq: "dKWr9uOdHlAM5tnoxUNsr"}, node_locale: {eq: "en-ZA"}}) {
    edges {
      node {
        seoTitle
        seoKeywords
        seoDescription
        slug
        node_locale
        media {
          file {
            url
          }
        }
        banner {
          image {
            file {
              url
            }
          }
          mainActionUrl
          mainActionText
          secondActionUrl
          secondActionText
		  thirdActionUrl
		  thirdActionText
          description {
            json
          }
        }
      }
    }
  }
	whichModel: allContentfulBanner(
		filter: {contentful_id: {eq: "5UBkM4QSv0bHOcKjyMBmw1"}, node_locale: {eq: "en-ZA"} }
	) {
		edges {
			node {
				hook
				node_locale
				mainActionText
				mainActionUrl
				description {
					json
				}
				backgroundImage {
					file {
						url
					}
				}
			}
		}
	}
	reviews: allContentfulFeaturedItems(filter: {node_locale: {eq: "en-ZA"}, contentful_id: {eq: "7eklcAUx1dnVwK62d8gbVw"}}) {
		edges {
			node {
				title
				node_locale
				items {
					node_locale
					name
					body {
						json
					}
				}
			}
		}
	}
	videobanner: allContentfulBanner(filter: {node_locale: {eq: "en-ZA"}, contentful_id: {eq: "bsDAxl4WMF5PAoHTBe4V7"}}) {
		edges {
			node {
				hook
				node_locale
				videoThumbnail {
					file {
						url
					}
					description
				}
				image {
					description
					file {
						url
					}
				}
				videoBanner {
					file {
						url
					}
				}
				description {
					json
				}
			}
		}
	}
	dolphinfeature: allContentfulFeaturedItems(filter: {node_locale: {eq: "en-ZA"}, contentful_id: {eq: "5ZEZgO98FOLRu8g0NUEETq"}}) {
		edges {
			node {
				node_locale
				items {
					node_locale
					image {
						file {
							url
						}
						description
						title
					}
					body {
						json
					}
					name
				}
			}
		}
	}
	findDealer: allContentfulBanner(filter: {contentful_id: {eq: "3fzyCMMGyiea8UtcWHpUwR"}}) {
		edges {
			node {
				mainActionText
				mainActionUrl
				secondActionText
				secondActionUrl
				hook
				node_locale
				backgroundImage {
					file {
						url
					}
				}
				description {
					json
				}
			}
		}
  	}
	xxsImage: allContentfulPage(filter: {contentful_id: {eq: "dKWr9uOdHlAM5tnoxUNsr"}, node_locale: {eq: "en-ZA"} }) {
		edges {
			node {
				backgroundImage {
					fluid(
						maxWidth: 524
						maxHeight: 400
						quality: 90
						cropFocus: CENTER
						resizingBehavior: FILL
					) {
						src
						srcWebp
					}
				}
				node_locale
			}
		}
	}
	xsImage: allContentfulPage(filter: {contentful_id: {eq: "dKWr9uOdHlAM5tnoxUNsr"}, node_locale: {eq: "en-ZA"} }) {
		edges {
			node {
				backgroundImage {
					fluid(
						maxWidth: 767
						maxHeight: 500
						quality: 90
						cropFocus: RIGHT
						resizingBehavior: FILL
					) {
						src
						srcWebp
					}
				}
				node_locale
			}
		}
	}
	smImage: allContentfulPage(filter: {contentful_id: {eq: "dKWr9uOdHlAM5tnoxUNsr"}, node_locale: {eq: "en-ZA"} }) {
		edges {
			node {
				backgroundImage {
					fluid(
						maxWidth: 1023
						maxHeight: 600
						quality: 90
						cropFocus: CENTER
						resizingBehavior: FILL
					) {
						src
						srcWebp
					}
				}
				node_locale
			}
		}
	}
	mdImage: allContentfulPage(filter: {contentful_id: {eq: "dKWr9uOdHlAM5tnoxUNsr"}, node_locale: {eq: "en-ZA"} }) {
		edges {
			node {
				backgroundImage {
					fluid(
						maxWidth: 1349
						maxHeight: 700
						quality: 90
						cropFocus: CENTER
						resizingBehavior: FILL
					) {
						src
						srcWebp
					}
				}
				node_locale
			}
		}
	}
	lgImage: allContentfulPage(filter: {contentful_id: {eq: "dKWr9uOdHlAM5tnoxUNsr"}, node_locale: {eq: "en-ZA"} }) {
		edges {
			node {
				backgroundImage {
					fluid(
						maxWidth: 1920
						maxHeight: 700
						quality: 90
						cropFocus: BOTTOM
						resizingBehavior: FILL
					) {
						src
						srcWebp
					}
				}
				node_locale
			}
		}
	}
	dolphinLines: allContentfulFeaturedItems(filter: {contentful_id: {eq: "1vuxSEZXPjF4N1WpMgxZmj"}, node_locale: {eq: "en-ZA"}}) {
		edges {
			node {
				node_locale
				title
				subtitle
				items {
					name
					body {
						json
					}
					url
					image {
						title
						description
						fluid(maxWidth: 500, maxHeight: 500, quality: 90) {
							...GatsbyContentfulFluid
						}
					}
				}
			}
		}
	}
	productImage: allContentfulProduct(filter: {contentful_id: {in: ["4PDWDCd3bd7fOAaQ6i8WCE", "1T2Jd2wY1BNeFoctpLRPC9"]}}) {
		edges {
			node {
				node_locale
				seoTitle
				seoDescription
				slug
				sku
				price
				model
				brand
				active
				series
				categories {
					title: seoTitle
					slug
				}
				shippingClass
				images {
					fluid(maxWidth: 600, maxHeight: 400, quality: 60) {
						src
						srcWebp
					}
					description
				}
			}
		}
	}
	elitedealers:allContentfulBanner(filter: {contentful_id: {eq: "3kDhudBySXImZRyo8t6CO6"}, node_locale: {eq: "en-ZA"}}) {
		edges {
			node {
				node_locale
				backgroundImage {
					fluid(
						maxWidth: 1920
						maxHeight: 1400
						quality: 90
						cropFocus: RIGHT
						resizingBehavior: FILL
					) {
						src
						srcWebp
					}
				}
				description {
					json
				}
			}
		}
	}
	elitelg:allContentfulBanner(filter: {contentful_id: {eq: "3kDhudBySXImZRyo8t6CO6"}, node_locale: {eq: "en-ZA"}}) {
		edges {
			node {
				node_locale
				backgroundImage {
					fluid(
						maxWidth: 1400
						maxHeight: 1050
						quality: 90
						cropFocus: RIGHT
						resizingBehavior: FILL
					) {
						src
						srcWebp
					}
				}
			}
		}
	}
	elitemd:allContentfulBanner(filter: {contentful_id: {eq: "3kDhudBySXImZRyo8t6CO6"}, node_locale: {eq: "en-ZA"}}) {
		edges {
			node {
				node_locale
				backgroundImage {
					fluid(
						maxWidth: 1280
						maxHeight: 900
						quality: 90
						cropFocus: RIGHT
						resizingBehavior: FILL
					) {
						src
						srcWebp
					}
				}
			}
		}
	}
	elitesm:allContentfulBanner(filter: {contentful_id: {eq: "3kDhudBySXImZRyo8t6CO6"}, node_locale: {eq: "en-ZA"}}) {
		edges {
			node {
				node_locale
				backgroundImage {
					fluid(
						maxWidth: 1024
						maxHeight: 800
						quality: 90
						cropFocus: RIGHT
						resizingBehavior: FILL
					) {
						src
						srcWebp
					}
				}
			}
		}
	}
	elitemxs:allContentfulBanner(filter: {contentful_id: {eq: "3kDhudBySXImZRyo8t6CO6"}, node_locale: {eq: "en-ZA"}}) {
		edges {
			node {
				node_locale
				backgroundImage {
					fluid(
						maxWidth: 768
						maxHeight: 700
						quality: 90
						cropFocus: RIGHT
						resizingBehavior: FILL
					) {
						src
						srcWebp
					}
				}
			}
		}
	}
	elitexs:allContentfulBanner(filter: {contentful_id: {eq: "3kDhudBySXImZRyo8t6CO6"}, node_locale: {eq: "en-ZA"}}) {
	edges {
		node {
			node_locale
			backgroundImage {
				fluid(
					maxWidth: 524
					maxHeight: 600
					quality: 90
					cropFocus: RIGHT
					resizingBehavior: FILL
				) {
					src
					srcWebp
				}
			}
		}
	}
  }
}
`

export default Dolphin
